import { Dialog } from '@headlessui/react';
import { DataRoomClientFile } from '@server/modules/client-matter/common/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ExitIcon from '@/assets/images/x-button-icon.svg';
import { ErrorContent } from '@/components/ErrorContent';
import LoadingSpinner from '@/components/LoadingSpinner';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';
import { useFilesAndEmptyFolders, useOverview } from '@/pages/overview/common/utils';
import { OrganizedView } from '@/pages/overview/dataroom/content/OrganizedView';
import { createAndSelectRedlineFiles } from '@/pages/overview/redline/utils';

interface FileListRedlineModalProps {
  showFileList: boolean;
  setShowFileList: (val: boolean) => void;
  redlineProcessing: boolean;
  setRedlineProcessing: (val: boolean) => void;
  setShowError: (val: boolean) => void;
}

export const FileListRedlineModal = ({
  showFileList,
  setShowFileList,
  redlineProcessing,
  setRedlineProcessing,
  setShowError,
}: FileListRedlineModalProps) => {
  const navigate = useNavigate();
  const { flags } = useFlags();
  const {
    matter: {
      id: clientMatterId,
      number: matterNumber,
      client: { number: clientNumber },
      redlines,
      fileUploadErrored,
      folderConfig,
      dataRoomFiles,
    },
  } = useOverview();
  const { isCheckAllActive, checkedFiles, setCheckedFiles, setIsCheckAllActive } = useDataRoom();

  useEffect(() => {
    if (isCheckAllActive) {
      setCheckedFiles([...dataRoomFiles]);
    } else {
      setCheckedFiles([]);
    }
  }, [dataRoomFiles, isCheckAllActive, setCheckedFiles]);

  const onDataroomSelection = useCallback(
    async (files: DataRoomClientFile[]) => {
      if (files.length === 0) return;
      setRedlineProcessing(true);
      const redlineName = `Redline ${redlines.length + 1}`;
      try {
        await createAndSelectRedlineFiles(
          files,
          matterNumber,
          clientNumber,
          clientMatterId,
          redlineName,
          flags?.redlineFormatting,
        );
        navigate(`${redlineName}/clustering`);
      } catch (e) {
        setRedlineProcessing(false);
        setShowFileList(false);
        setShowError(true);
      }
    },
    [
      setRedlineProcessing,
      redlines.length,
      matterNumber,
      clientNumber,
      clientMatterId,
      navigate,
      setShowFileList,
      setShowError,
      flags?.redlineFormatting,
    ],
  );

  const filesAndEmptyFolders = useFilesAndEmptyFolders(dataRoomFiles, folderConfig);

  const handleFileRedlineModalClose = () => {
    setShowFileList(false);
    setIsCheckAllActive(false);
  };

  const FileListContentDisplay = () => {
    if (fileUploadErrored) {
      return <ErrorContent />;
    }
    return (
      <>
        <OrganizedView filesByPath={filesAndEmptyFolders} dataRoomInteraction={false} />
      </>
    );
  };

  return (
    <Dialog
      open={showFileList}
      onClose={handleFileRedlineModalClose}
      className="fixed inset-0 z-10 flex h-full items-center justify-center bg-black/50 "
      data-testid="filelist-redline-modal"
    >
      <Dialog.Panel
        className={
          'flex h-[68vh] w-[65%] translate-y-10 flex-col rounded-[5px] border-2 border-light-border bg-container-dark'
        }
      >
        {redlineProcessing && (
          <div className="absolute inset-0 z-10 flex flex-col items-center justify-center bg-[#00000081] text-marveri-white">
            <h1 className="mb-4 rounded-[5px] bg-container-darkest p-2 font-bold">
              Generating Clusters, this will only take a moment
            </h1>
            <LoadingSpinner size={20} />
          </div>
        )}
        <div
          className="flex items-center justify-between border-b-2 border-b-light-border p-4"
          data-testid="redline-modal-select-data-room"
        >
          <h1 className="w-full text-center font-bold text-marveri-white">Select Documents From</h1>
          <MarveriIcon
            icon={ExitIcon}
            iconStyle={'h-[18px] w-[18px]'}
            iconType="other"
            onClick={handleFileRedlineModalClose}
          />
        </div>
        <div className="my-[20px] flex h-[50vh] flex-col px-[16px] text-marveri-white small:h-[48vh] xsmall:h-[40vh] xxsmall:h-[35vh]">
          <div className="mb-4 flex w-full border-b border-b-light-border font-bold text-marveri-light-silver">
            <div>
              <input
                type="checkbox"
                onChange={() => {
                  setIsCheckAllActive(!isCheckAllActive);
                }}
                className={`size-4 cursor-pointer appearance-none border border-light-border bg-marveri-background bg-contain bg-center bg-no-repeat checked:bg-[url('@/assets/images/check-icon-white.svg')]`}
              />
            </div>
            <div className="ml-4 flex w-full gap-[50%]">
              <h1>Name</h1>
            </div>
          </div>
          <ScrollableDiv>
            <FileListContentDisplay />
          </ScrollableDiv>
        </div>
        <div
          className={`absolute bottom-4 mr-[16px] flex flex-row self-end rounded-md border border-light-border px-[25px] py-[6px] text-marveri-white duration-[0.5s] ${
            checkedFiles.length > 0
              ? 'cursor-pointer bg-marveri-gold hover:bg-marveri-light-gold'
              : ''
          }`}
          onClick={() => onDataroomSelection(checkedFiles)}
          data-testid="redline-modal-drop-zone"
        >
          <span
            className={`pr-2 align-middle ${
              checkedFiles.length > 0 ? 'text-black' : 'text-marveri-light-silver'
            }`}
          >
            Next
          </span>
          <svg
            className="ml-2 w-5"
            fill={`${checkedFiles.length > 0 ? 'black' : '#868686'}`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};
