import { DataRoomClientFile } from '@server/modules/client-matter/common/types';

import { renameGivenExistingNamesForDuplicates } from '@/common/utils';
import { trpcClient } from '@/utils/trpc';

interface TempRedlineFile {
  name: string;
  url: string;
  extracted_text: string;
  average_file_embedding: number[];
}

export function renameDuplicateFilesForRedline(files: TempRedlineFile[]) {
  const usedFileNames = [] as string[];

  const newFiles = files.map((file) => {
    // TODO: there is probably a more efficient way to do this

    const fileName = file.name;
    const newName = renameGivenExistingNamesForDuplicates(fileName, usedFileNames);

    usedFileNames.push(newName);

    file.name = newName;

    return file;
  });
  return newFiles;
}

export async function createAndSelectRedlineFiles(
  redlineFilesUrls: DataRoomClientFile[],
  clientMatterNumber: number,
  clientNumber: number,
  clientMatterId: string,
  redlineName: string,
  redlineFormattingFeatureFlag: boolean,
) {
  const s3FilesUrls = redlineFilesUrls.map((file) => {
    return file.name;
  });

  await trpcClient.redline.createRedline.mutate({
    name: redlineName,
    matterNumber: clientMatterNumber,
    clientNumber: clientNumber,
    clientMatterId: clientMatterId,
  });

  await trpcClient.redline.addRedlineFilesFromDataRoom.mutate({
    fileNames: s3FilesUrls,
    redlineIdentifier: {
      name: redlineName,
      clientNumber: clientNumber,
      matterNumber: clientMatterNumber,
      clientMatterId: clientMatterId,
    },
    clientMatterNumber: clientMatterNumber,
    clientNumber: clientNumber,
    clientMatterId: clientMatterId,
  });

  if (redlineFormattingFeatureFlag) {
    const files = redlineFilesUrls.map((file) => {
      return {
        name: file.name,
        url: file.url,
      };
    });

    await trpcClient.redline.updateDocumentFormatting.mutate({
      clientNumber: clientNumber,
      clientMatterNumber: clientMatterNumber,
      files: files,
      clientMatterId: clientMatterId,
    });
  }
}

export async function deleteRedline(
  clientMatterNumber: number,
  clientNumber: number,
  clientMatterId: string,
  redlineName: string,
) {
  await trpcClient.redline.deleteRedline.mutate({
    name: redlineName,
    matterNumber: clientMatterNumber,
    clientNumber: clientNumber,
    clientMatterId: clientMatterId,
  });
}

export async function changeTemplate({
  matterNumber,
  clusterName,
  clientNumber,
  clientMatterId,
  redlineName,
  newTemplateFile,
}: {
  matterNumber: number;
  clientNumber: number;
  clientMatterId: string;
  redlineName: string;
  clusterName: string;
  newTemplateFile: string;
}) {
  await trpcClient.redline.setNewTemplateForCluster.mutate({
    clusterIdentifier: {
      redlineIdentifier: {
        clientNumber,
        matterNumber,
        clientMatterId,
        name: redlineName,
      },
      clusterName,
    },
    clientMatterId,
    newTemplateFileUrl: newTemplateFile,
  });
}
